.terms {
  margin-top: 0;
  padding: 200px 0 100px 0;
}

.terms h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.terms p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  padding: 20px;
  text-align: left;
}
.terms span {
  color: rgb(160, 160, 160);
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 700;
  padding: 20px;
}
