/************ Navbar Css ************/
nav.navbar {
  padding: 6px 0;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: 10%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 1px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.65;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

nav.navbar .navbar-nav .nav-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 1px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.65;
}
nav.navbar .navbar-nav .nav-link:hover {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 15px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  letter-spacing: 0.75px;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 20px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.dropdown-menu.show.dropdown-menu-dark {
  background-color: #121212;
  text-align: center;
}
/************ Footer ************/

.footer-link {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 16px;
}
.footer-link:hover {
  color: rgba(255, 255, 255);
  text-decoration: none;
}
.footer h6 {
  color: #fff;
}
.footer-contact {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 16px;
}
.footer-text {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 766px) {
  .vvd {
    position: relative;
    margin: auto;
  }
  .footer-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    list-style: none;
  }
}
